import React from 'react'
import '../App.css'
import feedbackfirst from '../images/FeedbackFirst.png'
import torontoEats from '../images/torontoEats.jpg'
import markdownPreviewer from '../images/markdownPreviewer.jpg'
import experli from '../images/Experli.png'

const projectData=[
{
    imageUrl:feedbackfirst,
    imageAlt:"screenshot of feedbackfirst",
    projectTitle:"MERN Stack Voting App",
    projectDescription:"Feedback first is a voting survey application built using the MERN Stack. The front end uses React with Redux for state management. The backend is built using Node.js with Express as server. Authentication is handled by Passport.js using Google authentication strategy. MongoDB Atlas is the database coupled with Mongoose. SendGrid is used as the emailing service. Payment service is handled using Stripe API.",
    liveProjectLink:"https://morning-brook-18359.herokuapp.com/",
    githubLink:"https://github.com/9raviy/FeedbackFirst"

},
  {
    imageUrl:torontoEats,
    imageAlt:"screenshot of torontoEats",
    projectTitle:"React Website TorontoEats",
    projectDescription:"TorontoEats is a fully responsive website built using React.js, HTML5, CSS, Javascript and Jquery. It is hosted on Github pages. TorontoEats is an initiative aimed at ensuring that no one in the City of Toronto and GTA gets to sleep without food. It is a food ordering website bringing to life a community initiative.",
    liveProjectLink:"https://9raviy.github.io/torontoEats/",
    githubLink:"https://github.com/9raviy/torontoEats/tree/master"
},
{
    imageUrl:markdownPreviewer,
    imageAlt:"screenshot of markdownPreviewer",
    projectTitle:"React Markdown Previewer",
    projectDescription:"React Markdown Previewer Markdown is a lightweight markup language with plain text formatting syntax. It is a simple way to add formatting like headers, bold, bulleted lists, and so on to a plain text. Markdown uses a simple formatting syntax to accomplish  the same thing that HTML does, but it is simpler than HTML.",
    liveProjectLink:"https://9raviy.github.io/markdownPreviewer/",
    githubLink:"https://github.com/9raviy/markdownPreviewer"

},
{
  imageUrl:experli,
  imageAlt:"screenshot of Experli",
  projectTitle:"Wordpress Website Experli",
  projectDescription:"The purpose of Experli is to provide expert local insights to newcomers in Canada in their employment and settlement related needs. The idea is to feel newcomers cared for. Let us pave the way for a happier society and help unlock the potential of youth to support Canadian economic prosperity. It is build using Bitnami Wordpress and hosted on AWS Lightsail with SSL certificate by LetsEncrypt.",
  liveProjectLink:"https://experli.com/"
},]

export default function projects() {
    return (
        
        <section className="projects">
        <div className="content-wrap divider">
          <h2>Featured Projects</h2>
          <p>
            View selected projects below. 
          </p>
          
            {projectData.map((project,index)=>(
                <section className="project-item">
                <img
                  src={project.imageUrl}
                  alt={project.imageAlt}
                />
                <h3>{project.projectTitle}</h3>
                <p>
                  {project.projectDescription}
                </p>
                <a
                  className="btn"
                  href={project.liveProjectLink}
                  target="_blank" rel="noopener noreferrer"
                  >View live project</a
                >
                <a
                  className="btn"
                  href={project.githubLink}
                  target="_blank" rel="noopener noreferrer"
                  >Github link</a
                >
              </section>
            ))}
        </div>
      </section>
    )
}
