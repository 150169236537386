import './App.css';
import Header from './Components/header'
import Projects from './Components/projects'
import Experience from './Components/experience'
import Education from './Components/education'
import Contact from './Components/contact'

function App() {
  return (
    <div className="App">
      <Header/>
      <Projects/>
      <Experience/>
      <Education/>
      <Contact/>
    </div>
  );
}

export default App;
