import React from 'react'

const experienceData=[{
    title:"Software Professional",
    titleDescription:"Freelance, Toronto, Canada",
    duration:"November 2018-Present",
    company:"",
    website:"",
    responsibilities:"Independently sourcing web and app development mandates to execute in partnership with software development firms.",
    achievements:[""]
},
{
    title:"Manager",
    titleDescription:"",
    duration:"March 2017 – October 2018",
    company:"SBI Capital Markets Limited, Delhi, India",
    website:"https://www.sbicaps.com/",
    responsibilities:"Executed investment banking fundraising mandates with primary responsibilities of     financial modelling, credit analysis and relationship management.",
    achievements:["Executed more than USD 1 billion of deals on capital projects and financing.","Consistently awarded outstanding expectations rating for every annual performance review."]
},
{
    title:"Co-Founder and Director",
    titleDescription:"",
    duration:"December 2015 - February 2017",
    company:"Intellinovate Solutions LLP, Delhi, India",
    website:"https://www.Intellinovate.com/",
    responsibilities:"Partnership focused on E-commerce in digital products.",
    achievements:["Achieved sales of more than USD 0.5 million in 6 months with a team of 4 employees"]
  },
  
  {
    title:"Manager",
    titleDescription:"",
    duration:"June 2011 - November 2015",
    company:"IFCI Limited, Delhi, India",
    website:"https://www.ifciltd.com/",
    responsibilities:"Managed a portfolio of corporate and project finance loans of more than USD 150 million    with key responsibilities for client acquisition, due diligence and portfolio monitoring.",
    achievements:["Managed a portfolio of USD 100 million of investments by actively monitoring their     performance with focus on achieving projected returns and managing key investment risks.","Consistently rated as exceeding expectations in the performance assessment for all the years."]
  },
  
  {
    title:"Assistant System Engineer",
    titleDescription:"",
    duration:"November 2005 - April 2009",
    company:"Tata Consultancy Services Limited, Mumbai, India",
    website:"https://www.tcs.com/",
    responsibilities:"Experience of working across the entire lifecycle of enterprise software development projects. Capture functional requirements, design, build, test and deploy software applications.",
    achievements:["Ensured development and deployment of a critical module in one of India’s largest capital     markets software development projects."]
  }
  ]

export default function experience() {
    return (
        <section className="work-experience">
        <div className="content-wrap item-details divider">
          <h2>Work Experience</h2>
          <p>
            See my complete work history on  
            <a href="https://www.linkedin.com/in/9raviy/"> LinkedIn</a>
          </p>

        {experienceData.map((experience,index)=>(
            <section className="job-item">
            <div className="job-details">
              <h3>{experience.title}</h3>
              
              <p><a href={experience.website}>{experience.company}</a></p>
              <p>{experience.titleDescription}
              </p>
            </div>
            <div className="job-summary">
              <p>
                {experience.responsibilities}
              </p>
              {experience.achievements[0]?<div><p>Key achievements:</p>
              <ul>
                  {experience.achievements.map((achievement,index)=>(
                      <li>{achievement}</li>
                  ))}
              </ul></div>:""}

            </div>
          </section>
        ))}
        </div>
      </section>
    )
}
