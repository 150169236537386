import React from 'react'

export default function header() {
    return (
        <header>
        <div className="content-wrap">
          <h1>Ravi Yadav</h1>
          <h2>Full stack developer</h2>

          <p>
            Experienced react based full stack developer with a multitude of
            coding skills in both front-end and backend with experience of
            deploying applications on Cloud. 
            <p></p> Four years of agile
            development experience as a Software Engineer (Bachelor of
            Technology) and more than five years of experience working in
            corporate finance (MBA and CFA Level II Pass).
          </p>
        </div>
      </header>
    )
}
