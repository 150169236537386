import React from 'react'

export default function contact() {
    return (
        <footer>
        <div class="content-wrap">
          <h2>Let's Keep in Touch!</h2>

          <ul class="contact-list">
            <li><a href="mailto:9raviy@iima.ac.in">9raviy@iima.ac.in</a></li>
            <li><a href="https://www.linkedin.com/in/9raviy/" target="_blank" rel="noopener noreferrer">LinkedIn</a></li>
          </ul>
        </div>
      </footer>
    )
}
