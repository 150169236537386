import React from 'react'

export default function education() {
    return (
        <section className="education">
        <div className="content-wrap item-details">
          <h2>Education</h2>

          <section>
            <h3><a href="https://www.udacity.com/">Udacity</a> </h3>
            <p>Full Stack and Front-End Web Developer Nanodegree, 2020</p>
            <p>
              Design and build databases for software applications, create and deploy database-backed web APIs, 
              and secure and manage user authentication and access control for an application backend.
            </p>
          </section>
          <section>
            <h3><a href="https://www.cfainstitute.org/">Chartered Financial Institute, Virginia, United States</a></h3>
            <p>CFA Level II Pass</p>
            <p>Scored >70% in all sections in Level I</p>
          </section>

          <section>
            <h3><a href="https://www.iima.ac.in/">Indian Institute of Management, Ahmedabad, India</a></h3>
            <p>Master of Business Administration, 2009-2011</p>
          </section>
          <section>
            <h3><a href="http://www.mnnit.ac.in/">Motilal Nehru National Institute of Technology, Allahabad, India</a></h3>
            <p>Bachelor of Technology, 2001-2005</p>
            <p>Graduated with Honours</p>
          </section>
        </div>
      </section>
    )
}
